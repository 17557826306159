import ApplicationAbility from './application';

export default class IntegrationAbility extends ApplicationAbility {
  get canView() {
    return this.permissions.integrations.read_secret_key && !this.organization.underRegistration;
  }

  get canEdit() {
    return this.permissions.integrations.create_secret_key;
  }

  get canNavigate() {
    return this.canView;
  }
}
