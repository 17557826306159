import { service } from '@ember/service';

import ApplicationAbility from './application';

export default class TransactionAbility extends ApplicationAbility {
  @service subscriptionManager;

  get canSeePromotionalCard() {
    let pricePlans = ['team_business', 'team_enterprise'];

    return (
      this.canRead &&
      this.canExport &&
      // eslint-disable-next-line no-restricted-syntax
      pricePlans.includes(this.subscriptionManager.currentPricePlan?.groupCode)
    );
  }

  get canRead() {
    return this.permissions.transactions.read;
  }

  get canExport() {
    return this.permissions.transactions.export;
  }

  get canUpdate() {
    return this.permissions.transactions.update;
  }

  get canUpdateAttachmentRequiredStatus() {
    return this.permissions.transactions.updateAttachmentRequiredStatus;
  }

  get canAccessAccountantFeatures() {
    return Boolean(this.pricePlanFeatures.accountantAccess);
  }

  get canQualifyForAccounting() {
    return (
      this.permissions.transactions.qualifyForAccounting &&
      Boolean(this.pricePlanFeatures.transactionsReview)
    );
  }

  canRequestAttachments(transaction) {
    let initiatorId = transaction?.initiatorId ?? transaction?.initiator?.id;

    if (!initiatorId || initiatorId === this.membership?.id) return false;

    return this.permissions.transactions.requestAttachments;
  }
}
