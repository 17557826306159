import ApiAdapter from 'qonto/adapters/base/api';
import { bankConnectionNamespace } from 'qonto/constants/hosts';

export default class BankConnectionAdapter extends ApiAdapter {
  namespace = bankConnectionNamespace;

  pathForType() {
    return 'account_aggregation/connections';
  }
}
