import ApplicationAbility from './application';

export default class PaymentActivationAbility extends ApplicationAbility {
  get canAccess() {
    return this.permissions.payment_activation_action.access;
  }

  get canTopUpByCard() {
    return this.canAccess && this.permissions.organizations.management;
  }
}
