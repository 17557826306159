import { ConflictError } from '@ember-data/adapter/error';
import { service } from '@ember/service';

import { normalizeMFAErrors } from '@qonto/qonto-sca/utils/mfa-error';
import ActiveModelAdapter from 'active-model-adapter';

import { apiBaseURL, apiNamespace } from 'qonto/constants/hosts';

export default class ApplicationAdapter extends ActiveModelAdapter {
  @service networkManager;

  host = apiBaseURL;
  namespace = apiNamespace;

  get headers() {
    return this.networkManager.requestHeaders;
  }

  ajaxOptions() {
    let options = super.ajaxOptions(...arguments);

    options.credentials = 'include';

    return options;
  }

  shouldReloadRecord() {
    return true;
  }

  shouldBackgroundReloadRecord() {
    return false;
  }

  handleResponse(status, headers, payload, requestData) {
    if (status === 409) {
      return new ConflictError();
    }

    payload = normalizeMFAErrors(payload);

    let result = super.handleResponse(status, headers, payload, requestData);

    if (result instanceof Error) {
      result.status = status;
      result.url = requestData.url;
      result.method = requestData.method;

      /* 
        add the name of the adapter class to the result so we can use it
        before sending it to Sentry.

        For example, if the error happens in the TeamAdapter
        which extends this adapter the value will be 'TeamAdapter'
      */
      if (result) {
        result.adapter = this.constructor.name;
      }
    }
    if (status >= 400 && status !== 404 && status !== 422) {
      this.networkManager.handleNetworkError(result);
    }

    return result;
  }
}
