import { variation } from 'ember-launch-darkly';
import { reads } from 'macro-decorators';

import ApplicationAbility from './application';

export default class ReceivableInvoiceAbility extends ApplicationAbility {
  @reads('pricePlanFeatures.receivableInvoices') canFullyAccess;

  get canCreate() {
    return this.permissions.receivableInvoices.create;
  }

  get canNavigate() {
    let {
      organization: { legalCountry },
    } = this.organizationManager;

    let canAccess = variation('feature-invoices-ar-onboarding') ? this.canRead : this.canCreate;

    return legalCountry !== 'PT' && !this.organization.isDeactivated && canAccess;
  }

  get canRead() {
    return this.permissions.receivableInvoices.read;
  }

  get canUpdate() {
    return this.permissions.receivableInvoices.update;
  }

  get canMatch() {
    return this.canCreate && this.canRead && this.canUpdate;
  }

  get canExport() {
    return this.canRead;
  }

  get canImport() {
    let {
      organization: { legalCountry },
    } = this.organizationManager;

    return this.canCreate && legalCountry !== 'IT';
  }
}
