import { variation } from 'ember-launch-darkly';

import { ORGA_STATUS } from 'qonto/constants/organization';

import ApplicationAbility from './application';

export default class CustomLabelAbility extends ApplicationAbility {
  get canView() {
    return this.permissions.labels.access && this.organization.status !== ORGA_STATUS.DEACTIVATED;
  }

  get canAccess() {
    return Boolean(this.pricePlanFeatures.customLabels);
  }

  get canRead() {
    return this.permissions.labels.read;
  }

  get canNavigate() {
    return this.canView;
  }

  get canUse() {
    return this.canAccess;
  }

  get canUseMemo() {
    return (
      this.canUse &&
      this.featuresManager.isEnabled('displayCashflowV1') &&
      variation('feature--boolean-label-memorization')
    );
  }
}
