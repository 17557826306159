import JSONAPIAdapter from 'qonto/adapters/base/json-api';

export default class IdentitiesKycAdapter extends JSONAPIAdapter {
  namespace = 'v1';

  buildURL(modelName, id, snapshot, requestType, query) {
    let identityId = query.identityId;
    delete query.identityId;
    let url = new URL(super.buildURL(modelName, id, snapshot, requestType, query));
    url.pathname = url.pathname.replace(`identities_kycs`, `identities/${identityId}/kyc`);
    return url.toString();
  }
}
