import { bool } from 'macro-decorators';

import ApplicationAbility from './application';

export default class SelfInvoiceAbility extends ApplicationAbility {
  @bool('pricePlanFeatures.supplierInvoices') canAccess;

  get canCreate() {
    let hasPermission = this.permissions.self_invoices.create;
    let isItalianOrganization = this.organization.legalCountry === 'IT';

    return this.canAccess && hasPermission && isItalianOrganization;
  }

  get canRead() {
    let hasPermission = this.permissions.self_invoices.read;
    let isItalianOrganization = this.organization.legalCountry === 'IT';

    return this.canAccess && hasPermission && isItalianOrganization;
  }
}
