import ApplicationAbility from './application';

export default class PostMigrationAbility extends ApplicationAbility {
  get canView() {
    // We rely on the `hasMigrationCompletedFeature` feature flag to identify organizations created before H2 2020 and used the Core Bank System provided by Treezor (before we migrated to our own).
    return (
      this.permissions.settings.accessTreezorHistoricalData &&
      this.organizationManager.organization?.hasMigrationCompletedFeature
    );
  }

  get canNavigate() {
    return this.canView;
  }
}
