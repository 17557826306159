import { requestsNamespace } from 'qonto/constants/hosts';

import ApplicationAdapter from './application';

export default class RequestMultiTransferTransferAdapter extends ApplicationAdapter {
  namespace = requestsNamespace;

  urlForQuery(query) {
    if (query.request_multi_transfer_id) {
      return `${this.host}/${this.namespace}/requests/multi_transfers/${query.request_multi_transfer_id}/transfers`;
    }

    return super.urlForQuery(...arguments);
  }

  handleResponse(status, headers, payload, requestData) {
    let requestMultiTransferId = requestData.url.split('multi_transfers/')[1].split('/')[0];
    let result = super.handleResponse(status, headers, payload, requestData);
    result.transfers.forEach(function (transfer) {
      transfer.request_multi_transfer_id = requestMultiTransferId;
    });

    return result;
  }
}
