import { UnauthorizedError } from '@ember-data/adapter/error';

import ApiAdapter from 'qonto/adapters/base/api';
import { inviteNamespace } from 'qonto/constants/hosts';

export default class InviteAdapter extends ApiAdapter {
  namespace = inviteNamespace;

  handleResponse(status, headers, payload, requestData) {
    if (status === 401) {
      return new UnauthorizedError();
    }

    return super.handleResponse(status, headers, payload, requestData);
  }
}
