import { variation } from 'ember-launch-darkly';

import ApplicationAbility from './application';

export default class AccountsReceivableOnboardingAbility extends ApplicationAbility {
  get canRead() {
    return (
      variation('feature-invoices-ar-onboarding') &&
      this.permissions.receivableInvoicesSettings.read
    );
  }

  get canComplete() {
    return (
      variation('feature-invoices-ar-onboarding') &&
      this.permissions.receivableInvoicesSettings.write
    );
  }
}
