import ApplicationAbility from './application';

export default class DirectDebitCollectionAbility extends ApplicationAbility {
  get canRead() {
    return (
      this.permissions.direct_debit_collections.read &&
      Boolean(this.pricePlanFeatures.directDebitsCollection)
    );
  }

  get canActivate() {
    return this.permissions.direct_debit_collections.activate;
  }

  get canCreate() {
    return this.permissions.direct_debit_collections.create;
  }

  get canAccess() {
    return this.canRead && this.canActivate && this.canCreate;
  }

  get canAccessEbics() {
    return ['FR', 'DE'].includes(this.organizationManager.organization.legalCountry);
  }
}
