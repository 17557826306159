import ApplicationAbility from './application';

export default class BeneficiaryAbility extends ApplicationAbility {
  get canAccess() {
    return this.permissions.beneficiaries.access;
  }

  get canCreate() {
    return this.permissions.beneficiaries.create;
  }

  get canUpdate() {
    return this.permissions.beneficiaries.update;
  }

  get canDelete() {
    return this.permissions.beneficiaries.delete;
  }

  get canTrust() {
    return this.permissions.beneficiaries.trust;
  }

  get canUntrust() {
    return this.permissions.beneficiaries.untrust;
  }

  get canSeeSensitiveFields() {
    return this.permissions.beneficiaries.see_sensitive_fields;
  }
}
