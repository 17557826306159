import { requestsNamespace } from 'qonto/constants/hosts';

import ApplicationAdapter from './application';

export default class RequestMultiTransferAdapter extends ApplicationAdapter {
  namespace = requestsNamespace;

  handleResponse(status, headers, payload, requestData) {
    if (status === 422 && requestData.url.includes('/requests/multi_transfers/check')) {
      payload.errors.map(error => {
        if (error.source && error.source.pointer) {
          this._parseError(error);
        }

        return error;
      });
    }

    return super.handleResponse(status, headers, payload, requestData);
  }

  buildURL(modelName, id, snapshot, requestType) {
    let url = super.buildURL(modelName, id, snapshot, requestType);

    return url.replace(/\/request_multi_transfers/, '/requests/multi_transfers');
  }

  _parseError(error) {
    let { pointer } = error.source;

    if (pointer.includes('/iban')) {
      error.source.pointer = error.source.pointer.replace('/iban', '/beneficiaryIban');
    } else if (pointer.includes('/beneficiary_iban')) {
      error.source.pointer = error.source.pointer.replace('/beneficiary_iban', '/beneficiaryIban');
    } else if (pointer.includes('/attachment_ids')) {
      error.source.pointer = error.source.pointer.replace('/attachment_ids', '/attachments');
    }

    return error;
  }
}
