import ApiAdapter from 'qonto/adapters/base/api';
import { searchPresetNamespace } from 'qonto/constants/hosts';

export default class SearchPresetAdapter extends ApiAdapter {
  namespace = searchPresetNamespace;

  urlForDeleteRecord(id, modelName, snapshot) {
    let url = super.urlForDeleteRecord(...arguments);
    let { type, organization_id } = this.serialize(snapshot);

    if (type === 'default_preset') {
      url = url.replace(/\/search_presets/, '/search_presets/defaults');
    }

    return `${url}?organization_id=${organization_id}`;
  }

  urlForUpdateRecord(id, modelName, snapshot) {
    let url = super.urlForUpdateRecord(...arguments);
    let { type } = this.serialize(snapshot);

    if (type === 'default_preset') {
      url = url.replace(/\/search_presets/, '/search_presets/defaults');
    }

    return url;
  }
}
