import ApplicationAbility from './application';

export default class AllowedBankAccountAbility extends ApplicationAbility {
  get canView() {
    return this.permissions?.memberships?.read_allowed_bank_accounts;
  }

  canUpdate(membership) {
    let isItself = this.membership && this.membership.id === membership.id;

    return (
      this.permissions?.memberships?.update_allowed_bank_accounts &&
      !membership.revoked &&
      !isItself
    );
  }
}
