/* eslint-disable @qonto/no-import-roles-constants */
import { service } from '@ember/service';

import { ROLES } from 'qonto/constants/membership';

import ApplicationAbility from './application';

export default class MembershipAbility extends ApplicationAbility {
  @service featuresManager;

  canUpdateRole(membership) {
    let isItself = this.membership && this.membership.id === membership.id;
    let hasForbiddenRole = [ROLES.OWNER, ROLES.REPORTING].includes(membership.role);

    return (
      this.permissions.memberships.updateRole &&
      !hasForbiddenRole &&
      !membership.revoked &&
      !isItself
    );
  }

  canUpdateTeam() {
    return this.permissions.memberships.updateTeam;
  }

  canUpdateTeamOfMembership(membership) {
    return this.permissions.memberships.updateTeam && !membership.revoked;
  }

  canRead() {
    return this.permissions.memberships.read;
  }

  get canUpdateAccess() {
    return (
      this.permissions.memberships.update_access &&
      this.membership !== this.model &&
      !this.model.owner
    );
  }

  canDestroy(membership) {
    let isItself = this.membership && this.membership.id === membership.get('id');
    return (
      this.permissions.memberships.delete && membership.get('role') !== ROLES.OWNER && !isItself
    );
  }

  canViewAddress(membership) {
    return membership.get('role') === ROLES.OWNER;
  }

  get canUseIban() {
    return this.canCreateIban || this.canUpdateIban || this.canDeleteIban;
  }

  get canReviewExpenseReport() {
    return this.permissions.request_expense_reports.review;
  }

  get canUseMileages() {
    let { featuresManager, pricePlanFeatures } = this;
    return featuresManager.isEnabled('mileage') && Boolean(pricePlanFeatures.requests);
  }

  get canCreateIban() {
    return this.permissions.membership_personal_bank_details.create;
  }

  get canUpdateIban() {
    return this.permissions.membership_personal_bank_details.update;
  }

  get canDeleteIban() {
    return this.permissions.membership_personal_bank_details.delete;
  }
}
