import { ORGA_STATUS } from 'qonto/constants/organization';

import ApplicationAbility from './application';

export default class BudgetAbility extends ApplicationAbility {
  get canAccess() {
    return this.permissions.budgets.access;
  }

  get canNavigate() {
    return this.canAccess && this.organization.status !== ORGA_STATUS.DEACTIVATED;
  }

  get canUse() {
    return Boolean(this.pricePlanFeatures.teamBudget);
  }

  get canCreate() {
    return this.canUse && this.permissions.budgets.create;
  }

  get canUpdate() {
    return this.canUse && this.permissions.budgets.update;
  }

  get canRead() {
    return this.canUse && this.permissions.budgets.read;
  }

  get canReadDetails() {
    return this.canUse && this.permissions.budgets.read_details;
  }

  get canUpdateTransaction() {
    return this.canUse && this.permissions.transactions.update_budget;
  }
}
