import ApplicationAbility from './application';

export default class OrganizationAbility extends ApplicationAbility {
  get canAdd() {
    return this.permissions.organizations.update;
  }

  get canDeactivate() {
    return this.organization.hasAccountClosingFlow && this.permissions.organizations.deactivate;
  }

  get canReadDeactivation() {
    return (
      this.organization.hasAccountClosingFlow && this.permissions.organizations.read_deactivation
    );
  }

  get canManage() {
    return this.permissions.organizations.management;
  }

  get canCancelDeactivation() {
    return (
      this.organization.hasAccountClosingFlow && this.permissions.organizations.cancel_deactivation
    );
  }

  get canReadKycKybUpdateProcess() {
    return this.permissions.organizations.kyb_kyc_update;
  }

  get canViewSettings() {
    return this.permissions.organizations.update && !this.organization.underRegistration;
  }

  get canEdit() {
    return this.permissions.organizations.update;
  }

  get canViewEmailAccountant() {
    return this.featuresManager.isEnabled('emailAccountantBylawsRequirements');
  }

  get canEditVatNumber() {
    let isNotFrench = this.organization.legalCountry !== 'FR';
    return isNotFrench && this.permissions.organizations.update;
  }
}
