import {
  directDebitCollectionClientsNamespace,
  receivableInvoiceCustomerNamespace,
} from 'qonto/constants/hosts';

import ReceivableInvoicesBaseAdapter from './base/receivable-invoices';

export default class CustomerAdapter extends ReceivableInvoicesBaseAdapter {
  namespace = receivableInvoiceCustomerNamespace;
  pathForType() {
    return 'receivable_invoices/customers';
  }

  buildURL(modelName, id, snapshot, requestType, query) {
    let url = super.buildURL(...arguments);
    let context = snapshot?.adapterOptions?.context || query?.adapterOptions?.context;

    if (context === 'sdd') {
      let invoicingCustomerUrl = `${this.namespace}/${this.pathForType()}`;
      url = url.replace(
        invoicingCustomerUrl,
        `${directDebitCollectionClientsNamespace}/direct_debit_collection_clients`
      );

      delete query?.adapterOptions;
    }

    return url;
  }
}
