import { requestsNamespace, requestsV4Namespace } from 'qonto/constants/hosts';

import ApplicationAdapter from './application';

export default class RequestAdapter extends ApplicationAdapter {
  buildURL(modelName, id, snapshot, requestType) {
    let url = super.buildURL(...arguments);
    let fullNamespace = `${this.host}/${this.namespace}`;

    if (modelName === 'request' && requestType === 'query') {
      return url.replace(fullNamespace, `${this.host}/${requestsV4Namespace}`);
    }

    return url.replace(fullNamespace, `${this.host}/${requestsNamespace}`);
  }

  handleResponse(status, headers, payload, requestData) {
    payload.requests?.forEach(request => {
      request.type = `request_${request.request_type}`;
    });

    return super.handleResponse(status, headers, payload, requestData);
  }
}
