import ApplicationAbility from './application';

export default class EinvoicingSettingAbility extends ApplicationAbility {
  get canRead() {
    return this.permissions.einvoicingSettings.read;
  }
  get canWrite() {
    return this.permissions.einvoicingSettings.write;
  }
}
