import ApplicationAdapter from './application';

export default class DocumentAdapter extends ApplicationAdapter {
  urlForCreateRecord(modelName, snapshot) {
    // when uploading a document with subjectType of Organization you need to
    // send it to the subroute of the organization. This will likely be improved
    // when the process for file uploads from the company-creation makes its way
    // to the main API
    if (snapshot.attr('subjectType') === 'Organization') {
      let organizationId = snapshot.belongsTo('organization').id;
      return `${this.host}/v3/organizations/${organizationId}/documents`;
    }

    return super.urlForCreateRecord(...arguments);
  }
}
