import JSONAPIAdapter from 'qonto/adapters/base/json-api';
import { financingNamespace } from 'qonto/constants/hosts';

export default class FinancingAdapter extends JSONAPIAdapter {
  namespace = financingNamespace;

  pathForType() {
    return 'pay_later/financings';
  }
}
