import { getOwner } from '@ember/owner';

import ApiAdapter from 'qonto/adapters/application';
import { multiTransfersNamespace } from 'qonto/constants/hosts';
import BulkRelationErrors from 'qonto/utils/bulk-relation-errors';

export default class MultiTransferAdapter extends ApiAdapter {
  namespace = multiTransfersNamespace;

  handleResponse(status, headers, payload) {
    if (this.isInvalid(status, headers, payload)) {
      new BulkRelationErrors(getOwner(this)).extractAndInject(
        payload,
        'multi_transfer',
        'transfers'
      );
    }

    return super.handleResponse(...arguments);
  }
}
