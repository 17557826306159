/* eslint-disable @qonto/no-import-roles-constants */
import { ROLES } from 'qonto/constants/membership';

import ApplicationAbility from './application';

export default class RoleAbility extends ApplicationAbility {
  canUpdate(membership) {
    let isItself = this.membership && this.membership.id === membership.id;
    let hasForbiddenRole = [ROLES.OWNER, ROLES.REPORTING].includes(membership.role);

    return this.permissions.roles.update && !hasForbiddenRole && !membership.revoked && !isItself;
  }

  canRead() {
    return this.permissions.roles.read;
  }
}
