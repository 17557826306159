import ApplicationAbility from './application';

export default class AccountantAccess extends ApplicationAbility {
  get canAccess() {
    return Boolean(this.pricePlanFeatures.accountantAccess);
  }

  get canUse() {
    return this.canAccess;
  }
}
