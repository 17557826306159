import { variation } from 'ember-launch-darkly';

import ApplicationAbility from './application';

export default class ExternalBankRecommendationAbility extends ApplicationAbility {
  get canView() {
    return (
      variation('feature--bank-switch-account-aggregation') &&
      this.permissions.external_accounts.read &&
      this.permissions.transactions.read &&
      this.permissions.external_transfers.create &&
      this.permissions.supplier_invoices.upload &&
      this.permissions.cards.read &&
      this.permissions.mandates.manage
    );
  }
}
