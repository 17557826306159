import { InvalidError } from '@ember-data/adapter/error';
import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';

import { adapterAction } from '@mainmatter/ember-api-actions';

import JSONAPIAdapter from 'qonto/adapters/base/json-api';
import { receivableInvoiceNamespace } from 'qonto/constants/hosts';
import { STATUS } from 'qonto/constants/receivable-invoice';
import transformKeys from 'qonto/utils/transform-keys';

export default class ReceivableInvoicesBaseAdapter extends JSONAPIAdapter {
  @service organizationManager;
  @service store;
  @service sentry;

  namespace = receivableInvoiceNamespace;

  buildURL(modelName, id, snapshot, requestType, query) {
    let url = new URL(super.buildURL(modelName, id, snapshot, requestType, query));
    url.searchParams.append('organization_id', this.organizationManager.organization.id);
    return url.toString();
  }

  urlForPdf(id) {
    let url = new URL(`${super.urlForFindRecord(id, 'receivable-invoice')}/file`);

    url.searchParams.append('organization_id', this.organizationManager.organization.id);
    return url.toString();
  }

  @waitFor
  async getStats() {
    let response = await adapterAction(this, 'receivable-invoices', {
      method: 'GET',
      path: 'stats',
    });

    return transformKeys(response.invoice);
  }

  @waitFor
  async getLast() {
    let response = await adapterAction(this, 'receivable-invoices', {
      method: 'GET',
      path: 'last',
    });
    let model = this.store.modelFor('receivable-invoice');
    let serializer = this.store.serializerFor('receivable-invoice');
    return serializer.normalizeFindRecordResponse(this.store, model, response);
  }

  handleResponse(status, headers, payload, requestData) {
    if (status === 409) {
      let { errors } = payload;
      if (errors.some(e => e.code === 'invoice_number_already_exists')) {
        errors.forEach((e, index, errors) => {
          if (e.code === 'invoice_number_already_exists') {
            errors[index] = { ...e, source: { pointer: '/data/attributes/number' } };
          }
        });
        let error = new InvalidError(errors);
        error.status = status;
        error.url = requestData.url;
        error.method = requestData.method;
        return error;
      }
    }
    return super.handleResponse(status, headers, payload, requestData);
  }

  createRecord(store, model, snapshot) {
    return super.createRecord(store, model, this.#mapClientToCustomerModel(snapshot));
  }

  updateRecord(store, model, snapshot) {
    if (
      snapshot.modelName === 'receivable-invoice' &&
      !snapshot.adapterOptions?.partial &&
      snapshot.__attributes?.status === STATUS.DRAFT
    ) {
      this.sentry.captureException(
        new Error('A ReceivableInvoiceModel in draft status should not be updated with PATCH', {
          extra: {
            causes_bug:
              'https://www.notion.so/qonto/Invalid-invoice-update-status-request-6fa027c1ff0c4390aa960db62228efc7',
          },
        })
      );
    }
    return super.updateRecord(store, model, this.#mapClientToCustomerModel(snapshot));
  }

  #mapClientToCustomerModel(snapshot) {
    if (snapshot.modelName === 'receivable-invoice') {
      let customer = snapshot.belongsTo('customer');

      if (customer && customer.modelName === 'client-hub') {
        customer.modelName = 'customer';
      }
    }

    return snapshot;
  }
}
