import AuthAdapter from 'qonto/adapters/base/auth';

export default class OtpAdapter extends AuthAdapter {
  pathForType() {
    return 'mfas';
  }

  buildURL(modelName, id, snapshot, requestType) {
    let url = super.buildURL(...arguments);
    if (requestType === 'triggerSMS') {
      return url.replace(this.namespace, 'v5');
    } else {
      return url;
    }
  }
}
