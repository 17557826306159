import SubscriptionProduct from 'qonto/models/subscription-product';

import ApplicationAbility from './application';

export default class MemberAbility extends ApplicationAbility {
  get isOrgaRegistered() {
    return !this.organization.underRegistration;
  }

  get canAccess() {
    return (
      this.permissions.memberships.access &&
      this.isOrgaRegistered &&
      Boolean(this.pricePlanFeatures.additionalUsers)
    );
  }

  get canView() {
    return this.permissions.memberships.read;
  }

  get canAccessManagerRoleFeature() {
    return (
      Boolean(this.pricePlanFeatures.managerRole) ||
      Boolean(this.pricePlanFeatures.advancedManagerPermission)
    );
  }

  get canAccessAdvancedManagerRoleFeature() {
    return Boolean(this.pricePlanFeatures.advancedManagerPermission);
  }

  get canCreatePaidMembers() {
    return Boolean(this.pricePlanFeatures.additionalUsers);
  }

  get canNavigate() {
    return this.canAccess;
  }

  get isSoloPlan() {
    let userLimit;

    if (this.model) {
      if (this.model instanceof SubscriptionProduct) {
        userLimit = this.model.getLimitByFeatureCode('additional_users')?.value;
      } else {
        // pricePlan
        userLimit = this.model.get('userLimit');
      }
    } else {
      userLimit = this.subscriptionManager.getLimitByFeatureCode('additional_users');
    }

    return userLimit === 1 && !this.isTeamPlan;
  }

  get isTeamPlan() {
    if (this.model) {
      if (this.model instanceof SubscriptionProduct) {
        return this.model.hasFeature('team_management');
      } else {
        // pricePlan
        return this.model.get('features').includes('team_management');
      }
    }
    return Boolean(this.pricePlanFeatures.teamManagement);
  }

  get canAddAdminOnly() {
    return this.isSoloPlan;
  }
}
