import JSONAPIAdapter from '@ember-data/adapter/json-api';
import { service } from '@ember/service';
import { underscore } from '@ember/string';

import { pluralize } from 'ember-inflector';

import { apiBaseURL } from 'qonto/constants/hosts';

export default class extends JSONAPIAdapter {
  @service networkManager;

  host = apiBaseURL;
  namespace = 'v3';

  pathForType(modelName) {
    return underscore(pluralize(modelName));
  }

  get headers() {
    return this.networkManager.requestHeaders;
  }

  ajaxOptions() {
    let options = super.ajaxOptions(...arguments);
    options.credentials = 'include';
    return options;
  }

  shouldReloadRecord() {
    return true;
  }

  shouldBackgroundReloadRecord() {
    return false;
  }

  handleResponse(status, headers, payload, requestData) {
    let result = super.handleResponse(status, headers, payload, requestData);

    if (result instanceof Error) {
      result.status = status;
      result.url = requestData.url;
      result.method = requestData.method;
    }

    /* 
      add the name of the adapter class to the result so we can use it
      before sending it to Sentry.

      For example, if the error happens in the TeamAdapter
      which extends this adapter the value will be 'TeamAdapter'
    */
    if (result) {
      result.adapter = this.constructor.name;
    }

    return result;
  }
}
