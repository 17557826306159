import AdapterError from '@ember-data/adapter/error';

import BillerAdapter from 'qonto/adapters/base/biller';

export class DiscountError extends AdapterError {
  constructor(errors) {
    super();
    this.name = 'DiscountError';
    this.errors = errors;
  }
}

export default class DiscountAdapter extends BillerAdapter {
  handleResponse(status, headers, payload) {
    if (status === 429) {
      return new DiscountError(payload.errors);
    }

    return super.handleResponse(...arguments);
  }
}
