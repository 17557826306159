import { InvalidError } from '@ember-data/adapter/error';
import RestAdapter from '@ember-data/adapter/rest';
import { service } from '@ember/service';

import { apiBaseURL, supplierHubNamespace } from 'qonto/constants/hosts';

export default class SupplierAdapter extends RestAdapter {
  @service networkManager;

  namespace = supplierHubNamespace;
  host = apiBaseURL;

  get headers() {
    return this.networkManager.requestHeaders;
  }

  shouldReloadRecord() {
    return true;
  }

  shouldBackgroundReloadRecord() {
    return false;
  }

  ajaxOptions() {
    let options = super.ajaxOptions(...arguments);
    options.credentials = 'include';
    return options;
  }

  handleResponse(status, headers, payload) {
    if (status === 422 && payload.errors) {
      let error = new InvalidError(payload.errors);
      error.code = payload.errors[0].code;
      return error;
    }

    return super.handleResponse(...arguments);
  }

  updateRecord(store, type, snapshot) {
    let data = {};
    let serializer = store.serializerFor(type.modelName);

    serializer.serializeIntoHash(data, type, snapshot, { includeId: true });

    let id = snapshot.id;
    let url = this.buildURL(type.modelName, id, snapshot, 'updateRecord');

    return this.ajax(url, 'PUT', { data });
  }
}
