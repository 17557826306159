import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';

import { adapterAction } from '@mainmatter/ember-api-actions';

import ApiAdapter from 'qonto/adapters/base/api';
import { savingsAccountNamespace } from 'qonto/constants/hosts';
import transformKeys from 'qonto/utils/transform-keys';

export default class SavingsAccountAdapter extends ApiAdapter {
  @service organizationManager;

  namespace = savingsAccountNamespace;

  @waitFor
  async getInformation() {
    let data = { organization_id: this.organizationManager.organization.id };

    let response = await adapterAction(this, 'savings-account', {
      method: 'GET',
      path: 'information',
      data,
    });

    return transformKeys(response.information);
  }

  @waitFor
  async getSavingsRates() {
    let data = { organization_id: this.organizationManager.organization.id };

    let response = await adapterAction(this, 'savings-account', {
      method: 'GET',
      path: 'rate_grids',
      data,
    });

    return transformKeys(response.interest_rates);
  }

  @waitFor
  async createProcedure({ amount, fundOrigin, fundOriginOther, maturity } = {}) {
    let data = {
      amount,
      fund_origin: fundOrigin,
      fund_origin_other: fundOriginOther,
      maturity,
      organization_id: this.organizationManager.organization.id,
    };

    let response = await adapterAction(this, 'savings-account', {
      method: 'POST',
      path: 'create_procedure',
      data,
    });

    return transformKeys(response.savings_account);
  }

  @waitFor
  async signContract({ procedureId, fundOrigin, membershipFiscalResidences } = {}) {
    let data = {
      organization_id: this.organizationManager.organization.id,
      procedure_id: procedureId,
      fund_origin: fundOrigin,
      membership_fiscal_residences: membershipFiscalResidences,
    };

    return await adapterAction(this, 'savings-account', { method: 'POST', path: 'sign', data });
  }
}
