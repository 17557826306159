import BillerAdapter from 'qonto/adapters/base/biller';
import { billerNamespace, billerV2Namespace } from 'qonto/constants/hosts';

export default class InvoiceAdapter extends BillerAdapter {
  namespace = billerV2Namespace;

  urlForQuery(query) {
    if (query.postMigration) {
      let url = `${this.host}/${billerNamespace}/organizations/${query.organization_id}/migration/old_invoices`;

      delete query.organization_id;
      delete query.postMigration;
      return url;
    }

    return super.urlForQuery(...arguments);
  }
}
