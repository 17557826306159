import ApplicationAbility from './application';

export default class ProductAbility extends ApplicationAbility {
  get canRead() {
    return this.permissions.products.read;
  }

  get canWrite() {
    return this.permissions.products.write;
  }
}
