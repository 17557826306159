import JSONAPIAdapter from 'qonto/adapters/base/json-api';
import { paymentLinkNamespace } from 'qonto/constants/hosts';

export default class PaymentLinkMethodAdapter extends JSONAPIAdapter {
  namespace = paymentLinkNamespace;

  pathForType() {
    return 'methods';
  }
}
