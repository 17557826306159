import { decamelize } from '@ember/string';

import ApiAdapter from 'qonto/adapters/base/api';
import { transactionNamespace } from 'qonto/constants/hosts';
import transformKeys from 'qonto/utils/transform-keys';

export default class VatAdapter extends ApiAdapter {
  namespace = transactionNamespace;

  pathForType() {
    return 'transactions';
  }

  buildURL(modelName, id, snapshot, requestType) {
    if (requestType === 'createRecord') {
      let { transaction_id: transactionId } = this.serialize(snapshot);
      let transactionUrl = super.buildURL('transaction', transactionId, snapshot, 'updateRecord');

      return `${transactionUrl}/${modelName}`;
    } else {
      let { transactionId } = snapshot;
      let transactionUrl = super.buildURL('transaction', transactionId, snapshot, 'updateRecord');

      return `${transactionUrl}/${modelName}/${id}`;
    }
  }

  updateVat(vat) {
    return this.updateOrDeleteVat(vat, 'PATCH');
  }

  deleteVat(vat) {
    return this.updateOrDeleteVat(vat, 'DELETE');
  }

  updateOrDeleteVat(vat, method) {
    let url = this.buildURL('vat', vat.id, vat);

    return this.ajax(url, method, { data: { vat: transformKeys(vat, decamelize) } });
  }
}
