import { requestsNamespace } from 'qonto/constants/hosts';

import ApplicationAdapter from './application';

export default class RequestDirectDebitCollectionAdapter extends ApplicationAdapter {
  namespace = requestsNamespace;

  urlForQuery(query) {
    if (query.multi_request_id) {
      return `${this.host}/${this.namespace}/requests/multi_direct_debit_collections/${query.multi_request_id}/direct_debit_collections`;
    }

    return super.urlForQuery(...arguments);
  }

  handleResponse(status, headers, payload, requestData) {
    let requestMultiDirectDebitCollectionId = requestData.url
      .split('multi_direct_debit_collections/')[1]
      .split('/')[0];
    let result = super.handleResponse(status, headers, payload, requestData);

    result.request_direct_debit_collections.forEach(
      sdd => (sdd.request_multi_direct_debit_collection_id = requestMultiDirectDebitCollectionId)
    );

    return result;
  }
}
