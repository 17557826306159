import ApplicationAbility from './application';

export default class PagoPaAbility extends ApplicationAbility {
  get canCreate() {
    let isItalianOrg = this.organization.legalCountry === 'IT';
    let canCreate = this.permissions.pagopa.create;
    return isItalianOrg && canCreate;
  }

  get canNavigate() {
    return this.canCreate;
  }

  get canViewFilter() {
    let isItalianOrg = this.organization.legalCountry === 'IT';
    return isItalianOrg;
  }
}
