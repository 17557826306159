import JSONAPIAdapter from 'qonto/adapters/base/json-api';

export default class EInvoiceActivationAdapter extends JSONAPIAdapter {
  namespace = 'v3';

  buildURL() {
    let url = new URL(super.buildURL(...arguments));
    url.pathname = url.pathname.replace(
      /e_invoice_activations\/(.*)/,
      (_, id) => `organizations/${id}/e_invoice_activation`
    );
    return url.toString();
  }
}
