import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';

import { adapterAction } from '@mainmatter/ember-api-actions';

import ReceivableInvoicesBaseAdapter from './base/receivable-invoices';

export default class ReceivableInvoicesAdapter extends ReceivableInvoicesBaseAdapter {
  @service organizationManager;

  pathForType() {
    return 'receivable_invoices/invoices';
  }

  @waitFor
  async fetchEmailTemplate(invoiceId) {
    return await adapterAction(this, 'receivable-invoices', {
      method: 'GET',
      path: `${invoiceId}/email_template`,
    });
  }

  @waitFor
  async fetchDuplicates(invoiceId) {
    let duplicates = await adapterAction(this, 'receivable-invoices', {
      method: 'GET',
      path: `${invoiceId}/duplicates`,
    });
    let duplicatesIds = duplicates.data.map(({ id }) => id);

    await this.store.push({
      data: duplicates.data.map(duplicate => {
        duplicate.type = 'receivable-invoice';
        duplicate.relationships = {};
        return duplicate;
      }),
    });

    return duplicatesIds;
  }

  @waitFor
  async search(query) {
    let response = await adapterAction(this, 'receivable-invoices', {
      method: 'POST',
      path: 'search',
      data: query,
    });

    let normalizedInvoices = response.data.map(invoice =>
      this.store.normalize('receivable-invoice', invoice)
    );

    // Remove the `data` key from each normalized object
    normalizedInvoices = normalizedInvoices.map(item => item.data);

    await this.store.push({
      data: normalizedInvoices,
    });

    let storedInvoices = response.data.map(invoice =>
      this.store.peekRecord('receivable-invoice', invoice.id)
    );

    return { invoices: storedInvoices, meta: response.meta };
  }

  sendUrl(id) {
    let url = new URL(`${super.urlForFindRecord(id, 'receivable-invoice')}/send`);
    url.searchParams.append('organization_id', this.organizationManager.organization.id);
    return url.toString();
  }
}
