/* eslint-disable @qonto/no-import-roles-constants */
import { ROLES } from 'qonto/constants/membership';

import ApplicationAbility from './application';

export default class TransferLimitAbility extends ApplicationAbility {
  get canView() {
    return this.permissions.transfer_limits.read;
  }

  get canUpdate() {
    return this.permissions.transfer_limits.update;
  }

  canViewOwnRequestInfoDisclaimer(request) {
    let initiatorId = request.get?.('initiator.id') || request.initiator.id;
    let isRequestInitiator = initiatorId === this.organizationManager.membership.id;
    let isManager = this.organizationManager.membership.role === ROLES.MANAGER;
    let hasSpendLimits = this.organizationManager.membership.hasSpendLimits;

    return isRequestInitiator && isManager && hasSpendLimits;
  }

  /**
   * Checks if the current user can update spend limits and if the updated user
   * has the correct role for spend limits (manager).
   * @param {*} role the role of the updated user
   * @returns true if the current user has the right permit and if the updated user is a manager
   */
  canUpdateByRole(role) {
    return this.canUpdate && role === ROLES.MANAGER;
  }
}
