import ApplicationAbility from './application';

export default class MandateAbility extends ApplicationAbility {
  get canCreate() {
    return this.permissions.mandates.manage;
  }

  get canView() {
    return (
      this.permissions.mandates.read &&
      this.permissions.bank_accounts.full_account_details &&
      Boolean(this.pricePlanFeatures.directDebits)
    );
  }

  get canNavigate() {
    return this.canView;
  }

  canRequestRefund(mandate) {
    return mandate.get('refundable') && this.permissions.directDebits.requestRefund;
  }
}
