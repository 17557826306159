import JSONAPIAdapter from 'qonto/adapters/base/json-api';
import { receiptRemindersNamespace } from 'qonto/constants/hosts';

export default class ReceiptReminderAdapter extends JSONAPIAdapter {
  namespace = receiptRemindersNamespace;

  buildURL() {
    let url = new URL(super.buildURL(...arguments));
    url.pathname = url.pathname.replace(
      /receipt_reminders\/(.*)/,
      (_, id) => `organizations/${id}/receipt_reminders/settings`
    );
    return url.toString();
  }
}
