import { countryNamespace } from 'qonto/constants/hosts';

import ApplicationAdapter from './application';

export default class CountryAdapter extends ApplicationAdapter {
  namespace = countryNamespace;

  shouldBackgroundReloadAll() {
    return false;
  }
}
