import ApiAdapter from 'qonto/adapters/base/api';
import { insuranceHubNamespace } from 'qonto/constants/hosts';

export default class InsuranceContractAdapter extends ApiAdapter {
  namespace = insuranceHubNamespace;

  pathForType() {
    return 'insurances/insurance_contracts';
  }
}
