import { waitFor } from '@ember/test-waiters';

import { apiAction } from '@mainmatter/ember-api-actions';

import ApiAdapter from 'qonto/adapters/base/api';

export default class ExerciseAdapter extends ApiAdapter {
  namespace = 'v1';

  @waitFor
  async createRecord(store, type, snapshot) {
    let response = await super.createRecord(store, type, snapshot);

    response.exercise.periods.forEach((period, index) => {
      period.lid = snapshot.hasMany('periods')[index].identifier.lid;
    });

    return response;
  }

  updateRecord(store, type, snapshot) {
    if (snapshot.record.isStarted) {
      return this.#patchRecord(store, type, snapshot);
    } else {
      return this.#putRecord(store, type, snapshot);
    }
  }

  async #putRecord(store, type, snapshot) {
    let response = await super.updateRecord(store, type, snapshot);
    // workaround back-end deleting and creating new periods :(
    // we don't get same periods back with same ids
    response.exercise.periods.forEach((period, index) => {
      period.lid = snapshot.hasMany('periods')[index].identifier.lid;
    });
    for (let i = snapshot.record.periods.length - 1; i >= 0; i--) {
      snapshot.record.periods.at(i).unloadRecord();
    }
    return response;
  }

  async #patchRecord(store, type, snapshot) {
    let updatedPeriods = snapshot.hasMany('periods').filter(periodSnapshot => {
      return Object.keys(periodSnapshot.changedAttributes()).length > 0;
    });

    let updatedIds = updatedPeriods.map(period => {
      return period.id;
    });

    let data = { exercise: this.serialize(snapshot) };

    data.exercise.periods = data.exercise.periods.filter(period => {
      return updatedIds.includes(period.id);
    });

    return await apiAction(snapshot.record, {
      method: 'PATCH',
      data,
    });
  }

  buildURL(modelName, id, snapshot) {
    let budget_id = snapshot.belongsTo('budget').id;
    let url = new URL(super.buildURL(...arguments));
    url.pathname = url.pathname.replace(/exercises/, `budgets/${budget_id}/exercises`);
    return url.toString();
  }
}
