import HubAdapter from 'qonto/adapters/base/hub';
import { solutionInstanceV2Namespace } from 'qonto/constants/hosts';

export default class SolutionInstanceAdapter extends HubAdapter {
  pathForType() {
    return `${this.route}/solution_instances`;
  }

  urlForQuery(query, modelName) {
    let url = this.buildURL(modelName);

    // `type` parameter is supported only in v2
    if (query.type) {
      url = `${this.host}/${solutionInstanceV2Namespace}/solution_instances`;
    }

    return url;
  }
}
