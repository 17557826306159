import { getOwner } from '@ember/owner';

import ApiAdapter from 'qonto/adapters/application';
import BulkRelationErrors from 'qonto/utils/bulk-relation-errors';

export default class MultiBeneficiaryAdapter extends ApiAdapter {
  handleResponse(status, headers, payload) {
    if (this.isInvalid(status, headers, payload)) {
      new BulkRelationErrors(getOwner(this)).extractAndInject(
        payload,
        'multi_beneficiary',
        'beneficiaries'
      );
    }

    return super.handleResponse(...arguments);
  }
}
