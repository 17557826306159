import { requestsNamespace } from 'qonto/constants/hosts';

import ApplicationAdapter from './application';

export default class RequestMultiDirectDebitCollectionAdapter extends ApplicationAdapter {
  namespace = requestsNamespace;

  buildURL(modelName, id, snapshot, requestType) {
    let url = super.buildURL(modelName, id, snapshot, requestType);

    return url.replace(
      /\/request_multi_direct_debit_collections/,
      '/requests/multi_direct_debit_collections'
    );
  }
}
