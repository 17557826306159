import JSONAPIAdapter from '@ember-data/adapter/json-api';
import { service } from '@ember/service';

import { registerPartnersNamespace, registerPartnersURL } from 'qonto/constants/hosts';

export default class RegisterPartnersAdapter extends JSONAPIAdapter {
  @service networkManager;

  host = registerPartnersURL;
  namespace = registerPartnersNamespace;

  get headers() {
    return this.networkManager.requestHeaders;
  }

  ajaxOptions() {
    let options = super.ajaxOptions(...arguments);
    options.credentials = 'include';
    return options;
  }
}
