import ApplicationAbility from './application';

export default class SavingsAccountAbility extends ApplicationAbility {
  get canList() {
    return this.permissions.savings.read;
  }

  get canCreate() {
    return this.featuresManager.isEnabled('cashbeeSavings') && this.permissions.savings.create;
  }

  get canAccess() {
    return this.canList || this.canCreate;
  }
}
