import { service } from '@ember/service';

import { receivableInvoicesSettingsNamespace } from 'qonto/constants/hosts';

import ReceivableInvoicesBaseAdapter from './base/receivable-invoices';

export default class ReceivableInvoicesSettingsAdapter extends ReceivableInvoicesBaseAdapter {
  namespace = receivableInvoicesSettingsNamespace;

  @service organizationManager;

  pathForType() {
    return 'receivable_invoices/settings';
  }

  urlForCreateRecord(modelName, snapshot) {
    return `${super.urlForCreateRecord(...arguments)}/${snapshot.id}`;
  }

  createRecord(store, type, snapshot) {
    return this._upsertRecord(store, type, snapshot);
  }

  updateRecord(store, type, snapshot) {
    return this._upsertRecord(store, type, snapshot);
  }

  _upsertRecord(store, type, snapshot) {
    let data = {};
    let serializer = store.serializerFor(type.modelName);

    serializer.serializeIntoHash(data, type, snapshot, { includeId: true });

    let id = snapshot.id;
    let url = this.buildURL(type.modelName, id, snapshot, 'updateRecord');

    return this.ajax(url, 'PUT', { data });
  }
}
