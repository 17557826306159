import { requestsNamespace } from 'qonto/constants/hosts';

import ApplicationAdapter from './application';

export default class RequestExpenseReportAdapter extends ApplicationAdapter {
  namespace = requestsNamespace;

  pathForType() {
    return 'requests/expense_reports';
  }
}
