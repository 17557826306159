import ApplicationAbility from './application';

export default class OauthAbility extends ApplicationAbility {
  get canView() {
    return this.permissions.oauth.read_consents;
  }

  get canRevoke() {
    return this.permissions.oauth.revoke_consents;
  }

  get canLogin() {
    return this.permissions.oauth.login;
  }

  get canNavigate() {
    return this.canView;
  }
}
