import { and } from 'macro-decorators';

import ApplicationAbility from './application';

export default class CounterpartyAbility extends ApplicationAbility {
  @and('canView', 'pricePlanFeatures.suppliersManagement') canAccess;

  get canView() {
    return this.permissions.beneficiaries.access;
  }

  get canNavigate() {
    return this.canView;
  }

  get canUse() {
    return this.canAccess;
  }
}
