import { InvalidError } from '@ember-data/adapter/error';
import { waitFor } from '@ember/test-waiters';

import { adapterAction } from '@mainmatter/ember-api-actions';

import JSONAPIAdapter from 'qonto/adapters/base/json-api';
import { receivableInvoiceNamespace } from 'qonto/constants/hosts';
import transformKeys from 'qonto/utils/transform-keys';

export default class QuotesAdapter extends JSONAPIAdapter {
  namespace = receivableInvoiceNamespace;

  pathForType() {
    return 'receivable_invoices/quotes';
  }

  createRecord(store, type, snapshot) {
    return super.createRecord(store, type, this.#mapClientToCustomerModel(snapshot));
  }

  updateRecord(store, type, snapshot) {
    return super.updateRecord(store, type, this.#mapClientToCustomerModel(snapshot));
  }

  handleResponse(status, headers, payload, requestData) {
    if (status === 409) {
      let { errors } = payload;
      if (errors.some(e => e.code === 'quote_number_already_exists')) {
        errors.forEach((e, index, errors) => {
          if (e.code === 'quote_number_already_exists') {
            errors[index] = { ...e, source: { pointer: '/data/attributes/number' } };
          }
        });
        let error = new InvalidError(errors);
        error.status = status;
        error.url = requestData.url;
        error.method = requestData.method;
        return error;
      }
    }
    return super.handleResponse(status, headers, payload, requestData);
  }

  @waitFor
  async getLast() {
    let response = await adapterAction(this, 'quotes', {
      method: 'GET',
      path: 'last',
    });
    let model = this.store.modelFor('quote');
    let serializer = this.store.serializerFor('quote');
    return serializer.normalizeFindRecordResponse(this.store, model, response);
  }

  @waitFor
  async getStats() {
    let response = await adapterAction(this, 'quotes', {
      method: 'GET',
      path: 'stats',
    });

    return transformKeys(response.quote);
  }

  @waitFor
  async fetchEmailTemplate(invoiceId) {
    return await adapterAction(this, 'quotes', {
      method: 'GET',
      path: `${invoiceId}/email_template`,
    });
  }

  urlForPdf(id) {
    let url = new URL(`${super.urlForFindRecord(id, 'quote')}/file`);
    return url.toString();
  }

  sendUrl(id) {
    let url = new URL(`${super.urlForFindRecord(id, 'quote')}/send`);
    return url.toString();
  }

  #mapClientToCustomerModel(snapshot) {
    let customer = snapshot.belongsTo('customer');

    if (customer && customer.modelName === 'client-hub') {
      customer.modelName = 'customer';
    }

    return snapshot;
  }
}
