import { waitFor } from '@ember/test-waiters';

import { adapterAction } from '@mainmatter/ember-api-actions';

import AttachmentCollectorAdapter from 'qonto/adapters/base/attachment-collector';

export default class AllowedEmailAdapter extends AttachmentCollectorAdapter {
  @waitFor
  async getBulkEmail(organizationId) {
    let { email_address } = await adapterAction(this, 'emailAddress', {
      method: 'GET',
      data: {
        organization_id: organizationId,
      },
    });
    return { email: email_address };
  }
}
