import { waitFor } from '@ember/test-waiters';

import { adapterAction } from '@mainmatter/ember-api-actions';

import JSONAPIAdapter from 'qonto/adapters/base/json-api';
import { receivableInvoiceNamespace } from 'qonto/constants/hosts';

export default class ReceivableInvoicesExportAdapter extends JSONAPIAdapter {
  namespace = receivableInvoiceNamespace;

  pathForType() {
    return 'receivable_invoices/export';
  }

  @waitFor
  async export({ dateFrom, dateTo, format, groupByStatuses }) {
    await adapterAction(this, 'receivable-invoice-export', {
      method: 'POST',
      data: {
        date_from: dateFrom,
        date_to: dateTo,
        format,
        group_by_statuses: groupByStatuses,
      },
    });
  }
}
