import ApplicationAdapter from './application';

export default class IntegrationAdapter extends ApplicationAdapter {
  buildURL(modelName, id, snapshot, requestType) {
    let url = super.buildURL(...arguments);

    if (requestType === 'createRecord') {
      let organizationId = snapshot.belongsTo('organization').id;
      url += `?organization_id=${organizationId}`;
    }

    return url;
  }
}
