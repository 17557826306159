import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';

import { adapterAction } from '@mainmatter/ember-api-actions';

import ApiAdapter from 'qonto/adapters/base/api';
import transformKeys from 'qonto/utils/transform-keys';

export default class RemuneratedAccountAdapter extends ApiAdapter {
  @service organizationManager;

  namespace = 'v1';

  urlForQuery(query) {
    if (query.organization_id) {
      let url = `${super.urlForQuery(...arguments)}/${query.organization_id}`;
      delete query.organization_id;
      return url;
    }
    return super.urlForQuery(...arguments);
  }

  handleResponse(status, headers, payload, requestData) {
    if (payload.accounts) {
      payload.remunerated_accounts = payload.accounts.map(value => value.remuneration_details);
    }
    return super.handleResponse(status, headers, payload, requestData);
  }

  @waitFor
  async getInformations() {
    let response = await adapterAction(this, `remunerated_accounts`, {
      method: 'GET',
      path: `${this.organizationManager.organization.id}`,
    });

    return transformKeys(response.accounts);
  }

  @waitFor
  async getDetails(accountId) {
    let response = await adapterAction(this, `remunerated_accounts`, {
      method: 'GET',
      path: `${this.organizationManager.organization.id}/accounts/${accountId}`,
    });

    return transformKeys(response);
  }

  @waitFor
  async getInterestRates() {
    let response = await adapterAction(this, 'remunerated_accounts', {
      method: 'GET',
      path: `${this.organizationManager.organization.id}/interest_rates`,
    });

    return transformKeys(response.interest_rates);
  }
}
