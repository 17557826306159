import { get } from '@ember/object';
import { service } from '@ember/service';

import { variation } from 'ember-launch-darkly';

import ApplicationAbility from './application';

export default class ConciergeAndLoungeAbility extends ApplicationAbility {
  @service organizationManager;

  // TODO cleanup this whenever C&L is enabled for all markets (expected Jan2025 or sooner)
  get canAccessDuringGeoExpansion() {
    return (
      !this.organizationManager.organization.isGeoExpansionLegalCountry ||
      !variation('feature--boolean-geo_expansion_concierge_lounge_disabled')
    );
  }

  get canAccess() {
    let hasFeaturesFlags =
      this.featuresManager.flags.concierge && this.featuresManager.flags.lounge;
    return (
      hasFeaturesFlags &&
      // TODO cleanup this whenever C&L is enabled for all markets (expected Jan2025 or sooner)
      this.canAccessDuringGeoExpansion
    );
  }

  get canViewLink() {
    let { model, membership } = this;
    let isCardHolder = get(model, 'holder.id') === get(membership, 'id');
    return this.canAccess && isCardHolder;
  }
}
