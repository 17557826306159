import JSONAPIAdapter from '@ember-data/adapter/json-api';
import { service } from '@ember/service';

import { apiBaseURL, solutionInstanceV2Namespace } from 'qonto/constants/hosts';

export default class GmiSolutionInstanceAdapter extends JSONAPIAdapter {
  @service networkManager;

  host = apiBaseURL;
  namespace = solutionInstanceV2Namespace;

  pathForType() {
    return 'gmi_solution_instances';
  }

  get headers() {
    return this.networkManager.requestHeaders;
  }

  ajaxOptions() {
    let options = super.ajaxOptions(...arguments);
    options.credentials = 'include';
    return options;
  }
}
