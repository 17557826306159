import JSONAPIAdapter from 'qonto/adapters/base/json-api';
import { externalBankRecommendationsNamespace } from 'qonto/constants/hosts';

export default class ExternalBankRecommendationAdapter extends JSONAPIAdapter {
  namespace = externalBankRecommendationsNamespace;

  pathForType() {
    return 'external_bank/recommendations';
  }
}
