import { InvalidError } from '@ember-data/adapter/error';

import ApiAdapter from 'qonto/adapters/base/api';
import { directDebitNamespace } from 'qonto/constants/hosts';

export default class DirectDebitAdapter extends ApiAdapter {
  namespace = directDebitNamespace;

  handleResponse(status, headers, payload) {
    // this ensures we don't go through the `errorsHashToArray()` call of the
    // active-model-adapter if the `errors` object content is unrelated to the
    // attributes of the model.
    if (status === 422 && payload.errors && payload.errors.code && payload.errors.message) {
      let error = new InvalidError([payload.errors]);
      error.code = payload.errors.code;
      return error;
    }

    return super.handleResponse(...arguments);
  }
}
