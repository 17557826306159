import { InvalidError } from '@ember-data/adapter/error';
import { waitFor } from '@ember/test-waiters';

import { adapterAction } from '@mainmatter/ember-api-actions';

import ApiAdapter from 'qonto/adapters/base/api';
import { checkNamespace } from 'qonto/constants/hosts';

export default class CheckAdapter extends ApiAdapter {
  namespace = checkNamespace;

  handleResponse(status, headers, payload) {
    // this ensures we don't go through the `errorsHashToArray()` call of the
    // active-model-adapter if the `errors` object content is unrelated to the
    // attributes of the model.

    if (status === 500 || status === 422) {
      if (Array.isArray(payload.errors)) {
        payload.errors = payload.errors[0];
      }

      if (payload.errors?.code) {
        let error = new InvalidError([payload.errors]);
        error.code = payload.errors.code;
        return error;
      }
    }

    return super.handleResponse(...arguments);
  }

  @waitFor
  async createMandate(organizationId) {
    return await adapterAction(this, 'check', {
      method: 'POST',
      path: 'mandate',
      data: {
        organization_id: organizationId,
      },
    });
  }

  @waitFor
  async getMandate(organizationId) {
    return await adapterAction(this, 'check', {
      method: 'GET',
      path: 'mandate',
      data: {
        organization_id: organizationId,
      },
    });
  }

  @waitFor
  async suspendMandate(organizationId) {
    return await adapterAction(this, 'check', {
      method: 'POST',
      path: 'mandate/revoke',
      data: {
        organization_id: organizationId,
      },
    });
  }

  @waitFor
  async preRegister(check) {
    return await adapterAction(this, 'check', {
      method: 'POST',
      path: 'pre-register',
      data: {
        cheque: check.serialize(),
      },
    });
  }
}
