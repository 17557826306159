import ApplicationAbility from './application';

export default class F24Ability extends ApplicationAbility {
  get canView() {
    let isItalianOrganization = this.organization.legalCountry === 'IT';
    return isItalianOrganization && this.canReadOrder;
  }

  get canReadOrder() {
    return this.permissions.f24_order.read;
  }

  get canNavigate() {
    return this.canView;
  }
}
