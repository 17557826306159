import { variation } from 'ember-launch-darkly';

import ApplicationAbility from './application';

export default class GmiIntegrationAbility extends ApplicationAbility {
  get canView() {
    return (
      variation('feature--boolean-gmi-active-connections') &&
      this.permissions.integration_solution_instances.read
    );
  }

  get canDisconnect() {
    return (
      variation('feature--boolean-gmi-active-connections') &&
      // The disconnect action relies on a v1 API endpoint that does not
      // check solution instance permissions, so we default  to checking the
      // marketplace access permission instead.
      this.permissions.integrations.access_marketplace
    );
  }
}
