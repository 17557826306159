import { service } from '@ember/service';

import { variation } from 'ember-launch-darkly';

import ApplicationAbility from './application';

export default class AccountAbility extends ApplicationAbility {
  @service abilities;

  get canCreate() {
    return (
      this.abilities.can('create bank-account') ||
      this.abilities.can('create savings-account') ||
      this.abilities.can('create remunerated-account') ||
      this.abilities.can('import external-account')
    );
  }

  get canAccessCreationFlow() {
    let isItalyBan =
      variation('operational--boolean-disable-account-creation-for-italy') &&
      this.organizationManager.organization.legalCountry === 'IT';
    return this.canCreate && !isItalyBan;
  }
}
