import JSONAPIAdapter from 'qonto/adapters/base/json-api';
import { clientHubNamespace } from 'qonto/constants/hosts';

export default class ClientHubAdapter extends JSONAPIAdapter {
  namespace = clientHubNamespace;

  pathForType() {
    return 'clients';
  }

  updateRecord(store, type, snapshot) {
    let data = {};
    let serializer = store.serializerFor(type.modelName);

    serializer.serializeIntoHash(data, type, snapshot, { includeId: true });

    let id = snapshot.id;
    let url = this.buildURL(type.modelName, id, snapshot, 'updateRecord');

    return this.ajax(url, 'PUT', { data });
  }
}
