import JSONAPIAdapter from 'qonto/adapters/base/json-api';
import { directDebitCollectionActivationNamespace } from 'qonto/constants/hosts';

export default class DirectDebitCollectionActivationAdapter extends JSONAPIAdapter {
  namespace = directDebitCollectionActivationNamespace;

  pathForType() {
    return 'direct_debit_collection/activation';
  }
}
