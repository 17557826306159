import AdapterError, { InvalidError } from '@ember-data/adapter/error';
import { service } from '@ember/service';

import ApiAdapter from 'qonto/adapters/base/api';
import { f24Namespace } from 'qonto/constants/hosts';

class ServiceUnavailableError extends AdapterError {
  constructor() {
    super(...arguments);
    this.name = 'ServiceUnavailableError';
    this.status = 503;
  }
}

export default class F24Adapter extends ApiAdapter {
  namespace = f24Namespace;

  @service organizationManager;

  pathForType() {
    return 'f24/orders';
  }

  handleResponse(status, headers, payload) {
    let serializer = this.store.serializerFor('f24-order');

    if (this.isInvalid(status, headers, payload)) {
      payload.errors = serializer.extractErrors(payload.errors);
      return new InvalidError(payload.errors);
    }

    if (status === 503) {
      return new ServiceUnavailableError();
    }

    return super.handleResponse(...arguments);
  }
}
