/* eslint-disable @qonto/no-import-roles-constants */
import { service } from '@ember/service';

import { variation } from 'ember-launch-darkly';

import { ROLES } from 'qonto/constants/membership';

import ApplicationAbility from './application';

export default class AccountingHubAbility extends ApplicationAbility {
  @service userManager;
  @service organizationManager;

  get reportingRolesCount() {
    let memberships = this.userManager.currentUser.memberships;
    return memberships.filter(({ role }) => role === ROLES.REPORTING).length;
  }

  get canAccess() {
    return variation('feature--boolean-login-improvement')
      ? Boolean(this.organizationManager.accountingOrganizations.length)
      : Boolean(this.reportingRolesCount);
  }

  get canAccessOrganization() {
    return this.permissions.accounting_hub.access;
  }

  get canAccessIntegrations() {
    return (
      this.organizationManager.organization.legalCountry === 'FR' &&
      (variation('feature--boolean-login-improvement')
        ? this.organizationManager.accountingOrganizations.length >= 3
        : this.reportingRolesCount >= 3)
    );
  }
}
