import { decamelize } from '@ember/string';

import transformKeys from 'qonto/utils/transform-keys';

import ApiAdapter from './base/api.js';

export default class BudgetTransactionAdapter extends ApiAdapter {
  namespace = 'v1';

  buildURL(modelName, id, snapshot, requestType, query) {
    let budget_id = query.budget_id;
    delete query.budget_id;
    let url = new URL(super.buildURL(modelName, id, snapshot, requestType, query));
    url.pathname = url.pathname.replace(/budget_transactions/, `budgets/${budget_id}/transactions`);
    return url.toString();
  }

  query(store, type, query) {
    query = transformKeys(query, decamelize);
    return super.query(store, type, query);
  }
}
