import JSONAPIAdapter from 'qonto/adapters/base/json-api';
import { receivableInvoiceNamespace } from 'qonto/constants/hosts';

export default class SelfInvoiceAdapter extends JSONAPIAdapter {
  namespace = receivableInvoiceNamespace;

  pathForType() {
    return 'receivable_invoices/self_invoices';
  }

  urlForPdf(id) {
    return new URL(`${super.urlForFindRecord(id, 'self-invoice')}/pdf`).toString();
  }
}
