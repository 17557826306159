import { variation } from 'ember-launch-darkly';

import ApplicationAbility from './application';

export default class PaymentLinkAbility extends ApplicationAbility {
  get isEligible() {
    let {
      organization: { legalCountry },
    } = this.organizationManager;

    return (
      ['FR', 'DE'].includes(legalCountry) && variation('feature--boolean-mollie-payment-links')
    );
  }

  get canRead() {
    return this.permissions.paymentLinks.read && this.isEligible;
  }

  get canWrite() {
    return this.permissions.paymentLinks.write && this.isEligible;
  }
}
