import { InvalidError } from '@ember-data/adapter/error';

import { normalizeMFAErrors } from '@qonto/qonto-sca/utils/mfa-error';

import JSONAPIAdapter from 'qonto/adapters/base/json-api';
import { pagopaNamespace } from 'qonto/constants/hosts';

export default class PagopaPaymentAdapter extends JSONAPIAdapter {
  namespace = pagopaNamespace;

  pathForType() {
    return 'pagopa/payments';
  }

  handleResponse(status, headers, payload, requestData) {
    let serializer = this.store.serializerFor('pagopa-payment');

    if (this.isInvalid(status, headers, payload)) {
      payload.errors = serializer.extractErrors(payload.errors);
      return new InvalidError(payload.errors);
    }

    let payloadWithMFAErrors = normalizeMFAErrors(payload);
    return super.handleResponse(status, headers, payloadWithMFAErrors, requestData);
  }
}
