import ApplicationAbility from './application';

export default class ReceivableInvoiceAbility extends ApplicationAbility {
  get canRead() {
    return Boolean(this.pricePlanFeatures.referralProgram);
  }

  get canTieredReferral() {
    return this.organization.hasTieredReferralReferrer;
  }
}
