import { requestsNamespace } from 'qonto/constants/hosts';

import ApplicationAdapter from './application';

export default class RequestTransferAdapter extends ApplicationAdapter {
  namespace = requestsNamespace;

  pathForType() {
    return 'requests/transfers';
  }

  handleResponse(status, headers, payload, requestData) {
    // turn backend `amount_cents` error key into `amount`
    if (
      status === 422 &&
      typeof payload.errors === 'object' &&
      !payload.errors.amount &&
      payload.errors.amount_cents
    ) {
      payload.errors.amount = payload.errors.amount_cents;
      delete payload.errors.amount_cents;
    }

    return super.handleResponse(status, headers, payload, requestData);
  }
}
