import { service } from '@ember/service';

import { variation } from 'ember-launch-darkly';

import ApplicationAbility from './application';

export default class QontoPilotAbility extends ApplicationAbility {
  @service organizationManager;

  get canUse() {
    let isOrgUnderRegistration = this.organizationManager.organization?.underRegistration;

    if (variation('feature--boolean-bankless-basic-plan')) {
      return (
        Boolean(this.pricePlanFeatures.qontoPilot) &&
        this.permissions.bank_accounts.create &&
        this.permissions.external_transfers.create &&
        !isOrgUnderRegistration
      );
    }

    return (
      this.permissions.bank_accounts.create &&
      this.permissions.external_transfers.create &&
      !isOrgUnderRegistration
    );
  }
}
