import { ORGA_STATUS } from 'qonto/constants/organization';

import ApplicationAbility from './application';

export default class RemuneratedAccountAbility extends ApplicationAbility {
  get isEligibleForRemuneration() {
    return (
      this.featuresManager.isEnabled('remuneratedAccountCreation') &&
      this.isKYBAccepted &&
      this.membership.kycAccepted &&
      this.organization.status === ORGA_STATUS.ACTIVATED
    );
  }

  get canCreate() {
    return this.permissions.bank_accounts.create && this.isEligibleForRemuneration;
  }

  get canRead() {
    return this.permissions.bank_accounts.read;
  }

  get canNavigate() {
    return this.isEligibleForRemuneration;
  }

  get canAccess() {
    return this.canCreate || this.isEligibleForRemuneration;
  }
}
