import { waitFor } from '@ember/test-waiters';

import { adapterAction } from '@mainmatter/ember-api-actions';

import AuthAdapter from 'qonto/adapters/base/auth';
import { authUserNamespace } from 'qonto/constants/hosts';

export default class UserAdapter extends AuthAdapter {
  namespace = authUserNamespace;

  urlForQueryRecord() {
    return `${this.host}/${this.namespace}/users/me`;
  }

  @waitFor
  async getDevices() {
    return await adapterAction(this, 'user', { method: 'GET', path: 'sessions/devices' });
  }
}
