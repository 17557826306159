import { debug } from '@ember/debug';
import { service } from '@ember/service';
import { underscore } from '@ember/string';
import { cached } from '@glimmer/tracking';

import { isTesting, macroCondition } from '@embroider/macros';
import { Ability } from 'ember-can';
import { bool, reads } from 'macro-decorators';

import ENV from 'qonto/config/environment';
import { ORGA_STATUS } from 'qonto/constants/organization';

const permissionGroupProxyHandler = function (organization) {
  return {
    get(permissionGroups, groupName) {
      let target =
        (permissionGroups[underscore(groupName)] || permissionGroups[groupName])?.rules || [];

      return new Proxy(target, {
        get(target, property) {
          // permissions are not linked to the organisation state (it would be a mix of concerns)
          // so we have to do an extra check to bypass BE permissions when required
          if (
            (organization?.status === ORGA_STATUS.DEACTIVATED ||
              organization?.status === ORGA_STATUS.SUSPENDED) &&
            !/(access|read|show|list|export)/i.test(property) &&
            groupName !== 'organizations' &&
            property !== 'update'
          ) {
            return false;
          }

          let value = target.some(rule => rule.action === underscore(property));

          if (macroCondition(isTesting())) {
            if (ENV.APP.LOG_USED_PERMISSIONS) {
              debug(
                `Permission "${underscore(
                  groupName
                )}.${property}" has been accessed and returned a value of "${value}"`
              );
            }
          }

          return value;
        },
      });
    },
  };
};

export default class ApplicationAbility extends Ability {
  @service organizationManager;
  @service subscriptionManager;
  @service featuresManager;
  @service launchdarkly;

  @reads('organizationManager.membership') membership;
  @reads('organizationManager.organization') organization;
  @reads('subscriptionManager.features') pricePlanFeatures;

  @bool('organization.kybAccepted') isKYBAccepted;

  @cached
  get permissions() {
    return new Proxy(
      this.membership?.permissions || {},
      permissionGroupProxyHandler(this.organization)
    );
  }
}
