import { MAXIMUM_AMOUNT_OF_TEAMS } from 'qonto/constants/teams';

import ApplicationAbility from './application';

export default class TeamAbility extends ApplicationAbility {
  get canView() {
    return this.permissions.teams.access;
  }

  get canAccess() {
    return Boolean(this.pricePlanFeatures.teamManagement) && this.canView;
  }

  get canRead() {
    return this.permissions.teams.read;
  }

  get canFilter() {
    let hasFeature = Boolean(this.pricePlanFeatures.teamManagement);
    let hasMultipleTeams = Boolean(this.organizationManager.organization.teams?.length > 1);
    return hasFeature && hasMultipleTeams;
  }

  get canCreate() {
    return Boolean(this.pricePlanFeatures.teamManagement) && this.permissions.teams.create;
  }

  get canCreateMore() {
    return (
      Boolean(this.pricePlanFeatures.teamManagement) &&
      this.permissions.teams.create &&
      this.organizationManager.organization.teams.length < MAXIMUM_AMOUNT_OF_TEAMS
    );
  }

  get canUpdate() {
    return Boolean(this.pricePlanFeatures.teamManagement) && this.permissions.teams.update;
  }

  get canDelete() {
    return Boolean(this.pricePlanFeatures.teamManagement) && this.permissions.teams.delete;
  }

  get canNavigate() {
    return this.canView;
  }

  get canUse() {
    return this.canAccess;
  }
}
