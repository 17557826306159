import { service } from '@ember/service';
import { underscore } from '@ember/string';
import { waitFor } from '@ember/test-waiters';

import { adapterAction } from '@mainmatter/ember-api-actions';

import ApiAdapter from 'qonto/adapters/base/api';
import { beneficiaryNamespace } from 'qonto/constants/hosts';

/**
 * Validates identifier fields
 * @param {Object} partialBeneficiary { iban, country , bic , accountNumber, routingNumber }
 * @returns {Promise<string> | Error}
 */
export default class BeneficiaryAdapter extends ApiAdapter {
  @service organizationManager;

  namespace = beneficiaryNamespace;

  @waitFor
  async validateBeneficiary(partialBeneficiary) {
    let data = {
      beneficiary: {
        ...this.serializePartialbeneficiary(partialBeneficiary),
        organization_id: this.organizationManager.organization.id,
      },
    };
    return await adapterAction(this, 'beneficiary', { method: 'POST', path: 'validate', data });
  }

  serializePartialbeneficiary(partialBeneficiary) {
    let newPartialBeneficiary = {};
    for (let key of Object.keys(partialBeneficiary)) {
      newPartialBeneficiary[underscore(key)] = partialBeneficiary[key];
    }
    return newPartialBeneficiary;
  }

  /**
   * Fetches additional requirements to create a beneficiary per country
   */
  @waitFor
  async fetchCountriesRequirements() {
    return await adapterAction(this, 'beneficiary', {
      method: 'GET',
      path: `requirements`,
    });
  }
}
