import { service } from '@ember/service';

import ApplicationAbility from './application';

export default class TaskAbility extends ApplicationAbility {
  @service abilities;

  get canNavigate() {
    let canAccessTask = Boolean(
      (this.abilities.can('review transfer request') ||
        this.abilities.can('review card request')) &&
        this.pricePlanFeatures.tasks
    );

    return this.abilities.can('read ebics request') || canAccessTask;
  }
}
