import ApplicationAbility from './application';

export default class InvoiceSubscriptionAbility extends ApplicationAbility {
  get canUse() {
    return !this.organization.isDeactivated && Boolean(this.pricePlanFeatures.recurringInvoices);
  }

  get canCreate() {
    return this.canUse && this.permissions.receivableInvoices.create;
  }

  get canRead() {
    return this.canUse && this.permissions.receivableInvoices.read;
  }

  get canUpdate() {
    return this.canUse && this.permissions.receivableInvoices.update;
  }
}
