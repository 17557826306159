import { service } from '@ember/service';

import ApiAdapter from 'qonto/adapters/base/api';

export default class PeriodComputedAmountAdapter extends ApiAdapter {
  @service store;

  namespace = 'v1';

  buildURL(modelName, id) {
    // period-computed-amounts.id matches period.id
    let period = this.store.peekRecord('period', id);
    let budget_id = period.exercise.budget.id;
    let url = new URL(super.buildURL(...arguments));
    url.pathname = url.pathname.replace(
      /period_computed_amounts/,
      `budgets/${budget_id}/period_computed_amounts`
    );
    return url.toString();
  }
}
