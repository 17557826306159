import { NotFoundError, UnauthorizedError } from '@ember-data/adapter/error';
import { waitFor } from '@ember/test-waiters';

import { adapterAction } from '@mainmatter/ember-api-actions';
import dayjs from 'dayjs';

import ApiAdapter from 'qonto/adapters/base/api';
import loadAllPaginated from 'qonto/routes/budgets/list/load-all-paginated';
import transformKeys from 'qonto/utils/transform-keys';

export default class BudgetAdapter extends ApiAdapter {
  namespace = 'v1';

  buildQuery(snapshot) {
    let query = super.buildQuery(snapshot);
    if (snapshot.include) {
      query.includes = snapshot.include;
    }
    return query;
  }

  @waitFor
  async createRecord(store, type, snapshot) {
    let response = await super.createRecord(store, type, snapshot);
    response.budget.exercises.forEach((exercise, index) => {
      let exerciseSnapshot = snapshot.hasMany('exercises')[index];
      exercise.lid = exerciseSnapshot.identifier.lid;
      exercise.periods.forEach((period, index) => {
        period.lid = exerciseSnapshot.hasMany('periods')[index].identifier.lid;
      });
    });
    return response;
  }

  @waitFor
  async getEligibleTeams() {
    return await adapterAction(this, 'budget', {
      method: 'GET',
      path: 'eligible_teams',
    });
  }

  @waitFor
  async search({ initiatorId, scheduledDate, includes = [] }) {
    let response = await adapterAction(this, 'budget', {
      method: 'GET',
      path: 'search',
      data: {
        initiator_id: initiatorId,
        scheduled_date: dayjs(scheduledDate).format('YYYY-MM-DD'),
        includes,
      },
    });

    let serializer = this.store.serializerFor('budget');

    response.results.forEach(result => {
      let normalizedResponse = serializer.normalize(this.store.modelFor('budget'), result.budget);
      let periodResponse = normalizedResponse.included.find(({ id }) => id === result.period_id);
      if (periodResponse && result.amounts)
        periodResponse.attributes.amountSettled = result.amounts.amount_settled;
      result.budget = this.store.push(normalizedResponse);
      result.period = this.store.peekRecord('period', result.period_id);
      delete result.period_id;
    });

    return response.results;
  }

  @waitFor
  async allocatablePeriods({ transactionId, perPage = 100 }) {
    try {
      let budgets = await loadAllPaginated(async page => {
        let result = await adapterAction(this, 'budget', {
          method: 'GET',
          path: `transactions/${transactionId}/allocatable_budgets`,
          data: { page, per_page: perPage },
        });

        result.budgets.meta = result.meta;

        return result.budgets;
      });

      let allocatablePeriods = budgets.map(budget => ({
        budget,
        allocatablePeriod: budget.exercises[0].periods[0],
      }));

      return transformKeys(allocatablePeriods);
    } catch (error) {
      if (error instanceof NotFoundError) {
        return [];
      } else {
        throw error;
      }
    }
  }

  @waitFor
  async allocatedPeriod(transactionId) {
    try {
      let result = await adapterAction(this, 'budget', {
        method: 'GET',
        path: `transactions/${transactionId}`,
      });
      let { budget, allocatedPeriodId } = transformKeys(result);
      // we dont push the payload into the store as it's a 'sparse' budget with just the period we are interested in
      let allocatedPeriod = null;
      budget.exercises.find(
        ({ periods }) => (allocatedPeriod = periods.find(({ id }) => id === allocatedPeriodId))
      );
      return { allocatedPeriod, budget };
    } catch (error) {
      if (error instanceof NotFoundError || error instanceof UnauthorizedError) {
        return { allocatedPeriod: null, budget: null };
      } else {
        throw error;
      }
    }
  }

  @waitFor
  async allocateTransactionToPeriod({ transactionId, periodId }) {
    let result = await adapterAction(this, 'budget', {
      method: 'PUT',
      path: `transactions/${transactionId}`,
      data: { allocated_period_id: periodId },
    });

    let { budget, allocatedPeriodId } = transformKeys(result);

    let allocatedPeriod = null;

    if (allocatedPeriodId) {
      budget.exercises.find(
        ({ periods }) => (allocatedPeriod = periods.find(({ id }) => id === allocatedPeriodId))
      );
    }

    return transformKeys({ allocatedPeriod, budget });
  }
}
