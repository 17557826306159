import { variation } from 'ember-launch-darkly';

import ApplicationAbility from './application';

export default class CardAcquirerPaymentAbility extends ApplicationAbility {
  // TODO: ttp-acceptance-list cleanup
  get canRead() {
    return (
      this.featuresManager.isEnabled('tapToPay') &&
      variation('feature--boolean-ttp-acceptance-list')
    );
  }

  // to display the entry point in the navigation (we keep atomic abilities)
  get canNavigate() {
    return this.canRead;
  }
}
