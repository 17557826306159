import ApplicationAbility from './application';

export default class OnboardingAbility extends ApplicationAbility {
  get canViewCard() {
    return (
      Boolean(this.pricePlanFeatures.physicalCard) && this.permissions.organizations.management
    );
  }

  get canViewTopup() {
    return this.organization.hasTopUpFeature && this.membership.topupsStatus === 'allowed';
  }

  get canResumeTopup() {
    return (
      this.organization.hasTopUpFeature &&
      ['skipped', 'failed', 'allowed'].includes(this.membership.topupsStatus)
    );
  }

  get canViewGetStartedActions() {
    return this.permissions.get_started_actions.access;
  }
}
