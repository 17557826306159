import { service } from '@ember/service';

import JSONAPIAdapter from 'qonto/adapters/base/json-api';
import { einvoicingSettingsNamespace } from 'qonto/constants/hosts';

export default class EinvoicingSettingsAdapter extends JSONAPIAdapter {
  @service organizationManager;

  namespace = einvoicingSettingsNamespace;

  pathForType() {
    return 'einvoicing/settings';
  }

  updateRecord(store, type, snapshot) {
    return this._upsertRecord(store, type, snapshot);
  }

  _upsertRecord(store, type, snapshot) {
    let data = {};
    let serializer = store.serializerFor(type.modelName);

    serializer.serializeIntoHash(data, type, snapshot, { includeId: true });

    let id = snapshot.id;
    let url = this.buildURL(type.modelName, id, snapshot, 'updateRecord');

    return this.ajax(url, 'PUT', { data });
  }
}
