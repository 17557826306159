import { variation } from 'ember-launch-darkly';

import ApplicationAbility from './application';

export default class FinancingAbility extends ApplicationAbility {
  get canAccess() {
    if (variation('feature--boolean-bankless-basic-plan')) {
      return Boolean(this.pricePlanFeatures.financingHub) && this.permissions.financing_hub.access;
    }

    return this.permissions.financing_hub.access;
  }

  get canRequest() {
    let isUboOrCorporateOfficer = this.membership.ubo || this.membership.corporateOfficer;

    return Boolean(this.isKYBAccepted && this.membership.kycAccepted && isUboOrCorporateOfficer);
  }

  get canAccessPayLater() {
    return (
      this.membership.corporateOfficer &&
      this.membership.kycAccepted &&
      this.permissions.pay_later_eligibility.read &&
      this.organization.hasPayLaterFeature &&
      Boolean(this.pricePlanFeatures.payLater)
    );
  }

  get canViewPayLaterToggle() {
    return this.canAccessPayLater && this.permissions.pay_later_external_transfers.create;
  }

  get canCreatePayLaterTransfer() {
    return this.canViewPayLaterToggle && !this.organization.hasPreventPayLaterToggle;
  }

  get canEarlyRepay() {
    return this.canAccessPayLater && this.permissions.pay_later_early_repayment.read;
  }

  get canNavigate() {
    return this.canAccess;
  }
}
