import ApplicationAbility from './application';

export default class RemindersConfigurationAbility extends ApplicationAbility {
  get canWrite() {
    return (
      this.permissions.receivable_invoices_reminders.write &&
      Boolean(this.pricePlanFeatures.receivableInvoicesReminders)
    );
  }
}
