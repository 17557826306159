import { reads } from 'macro-decorators';

import { getEnabledLegalCountries } from 'qonto/constants/insurance-hub';

import ApplicationAbility from './application';

export default class InsuranceAbility extends ApplicationAbility {
  @reads('organizationManager.organization') organization;

  get canRead() {
    return (
      Boolean(this.pricePlanFeatures.insurance) &&
      this.permissions.insurance_contracts.read &&
      getEnabledLegalCountries().includes(this.organizationManager.organization.legalCountry)
    );
  }

  get canNavigate() {
    return this.canRead;
  }
}
