import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';

import { adapterAction } from '@mainmatter/ember-api-actions';

import JSONAPIAdapter from 'qonto/adapters/base/json-api';
import { ErrorInfo } from 'qonto/utils/error-info';
import transformKeys from 'qonto/utils/transform-keys';

import { receivableInvoiceNamespace } from '../constants/hosts';

export default class InvoiceSubscriptionsAdapter extends JSONAPIAdapter {
  @service sentry;
  namespace = receivableInvoiceNamespace;

  pathForType() {
    return 'receivable_invoices/subscriptions';
  }

  createRecord(store, type, snapshot) {
    return super.createRecord(store, type, this.#mapClientToCustomerModel(snapshot));
  }

  updateRecord(store, type, snapshot) {
    snapshot = this.#mapClientToCustomerModel(snapshot);
    let data = {};
    let serializer = store.serializerFor(type.modelName);

    serializer.serializeIntoHash(data, type, snapshot, { includeId: true });

    let id = snapshot.id;
    let url = this.buildURL(type.modelName, id, snapshot, 'updateRecord');

    return this.ajax(url, 'PUT', { data });
  }

  @waitFor
  async getStats() {
    try {
      let response = await adapterAction(this, 'invoice-subscription', {
        method: 'GET',
        path: 'stats',
      });

      return transformKeys(response.subscription);
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
      return {
        created: {
          total: 0,
          active: 0,
          scheduled: 0,
          canceled: 0,
          suspended: 0,
          finished: 0,
        },
        quotas_remaining: null,
      };
    }
  }

  #mapClientToCustomerModel(snapshot) {
    let customer = snapshot.belongsTo('customer');

    if (customer && customer.modelName === 'client-hub') {
      customer.modelName = 'customer';
    }

    return snapshot;
  }
}
