import ApplicationAdapter from 'qonto/adapters/application';
import { subscriptionNamespace } from 'qonto/constants/hosts';

export default class SubscriptionAdapter extends ApplicationAdapter {
  namespace = subscriptionNamespace;
  headerOverrides = {};

  get headers() {
    return { ...this.networkManager.requestHeaders, ...this.headerOverrides };
  }

  query(store, type, query) {
    if (query?.adapterOptions?.headers) {
      this.headerOverrides = query.adapterOptions.headers;
    }
    query.adapterOptions = undefined;
    let promise = super.query(store, type, query);
    this.headerOverrides = {};
    return promise;
  }

  updateRecord(store, type, snapshot) {
    if (snapshot?.adapterOptions?.headers) {
      this.headerOverrides = snapshot.adapterOptions.headers;
    }
    snapshot.adapterOptions = undefined;
    let promise = super.updateRecord(store, type, snapshot);
    this.headerOverrides = {};
    return promise;
  }
}
