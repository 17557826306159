import JSONAPIAdapter from 'qonto/adapters/base/json-api';
import { userActionsV2Namespace } from 'qonto/constants/hosts';

export default class UserActionV2Adapter extends JSONAPIAdapter {
  namespace = userActionsV2Namespace;

  pathForType() {
    return 'user_actions';
  }

  _buildCustomUrl(modelName, snapshot) {
    let path;
    let url = [];
    let { host } = this;
    let prefix = this.urlPrefix();

    if (modelName) {
      path = this.pathForType(modelName);
      if (path) {
        url.push(path);
      }
    }

    // We use the `originalId` as the ID
    let actionName = snapshot.attributes().originalId;
    url.push(encodeURIComponent(actionName));

    if (prefix) {
      url.unshift(prefix);
    }

    let urlString = url.join('/');
    if (!host && urlString && urlString.charAt(0) !== '/') {
      urlString = '/' + urlString;
    }

    return urlString;
  }

  urlForUpdateRecord(id, modelName, snapshot) {
    return this._buildCustomUrl(modelName, snapshot);
  }
}
