import ApplicationAbility from './application';

export default class SupplierAbility extends ApplicationAbility {
  get canView() {
    return this.permissions.suppliers.read;
  }

  get canUpdate() {
    return this.permissions.suppliers.write;
  }
}
