import JSONAPIAdapter from 'qonto/adapters/base/json-api';
import { externalBankRecommendationsNamespace } from 'qonto/constants/hosts';

export default class ExternalBankRecommendationsBundleAdapter extends JSONAPIAdapter {
  namespace = externalBankRecommendationsNamespace;

  pathForType() {
    return 'external_bank/recommendations_bundles';
  }
}
