import ApplicationAbility from './application';

export default class CashFlowAbility extends ApplicationAbility {
  get canView() {
    return this.featuresManager.isEnabled('displayCashflowV1') && this.permissions.cash_flow.view;
  }

  get canNavigate() {
    return this.canView;
  }
}
