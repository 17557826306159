import ApplicationAbility from './application';

export default class ExternalAccountAbility extends ApplicationAbility {
  get canImport() {
    return this.isKYBAccepted && Boolean(this.permissions.external_accounts.import);
  }

  get canViewConnections() {
    return Boolean(this.permissions.external_accounts.read);
  }

  get canDeleteConnections() {
    return Boolean(this.permissions.external_accounts.delete);
  }
}
