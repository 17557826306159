import JSONAPIAdapter from 'qonto/adapters/base/json-api';

import { receivableInvoiceNamespace } from '../constants/hosts';

export default class AccountsReceivableOnboardingAdapter extends JSONAPIAdapter {
  namespace = receivableInvoiceNamespace;

  pathForType() {
    return 'receivable_invoices/onboarding';
  }

  buildURL(modelName, _id, snapshot, requestType) {
    // There is only ever one onboarding record, no need for id
    return super.buildURL(modelName, null, snapshot, requestType);
  }

  get uploadURL() {
    let url = new URL(`${super.urlForFindRecord(null, 'accounts-receivable-onboarding')}/upload`);
    return url.toString();
  }
}
