import { waitFor } from '@ember/test-waiters';

import { adapterAction } from '@mainmatter/ember-api-actions';

import ApiBaseAdapter from 'qonto/adapters/base/api';
import { bookkeepingTransactionsNamespace, transactionNamespace } from 'qonto/constants/hosts';

export default class BookkeepingTransactionAdapter extends ApiBaseAdapter {
  namespace = bookkeepingTransactionsNamespace;

  pathForType() {
    return 'bookkeeping/transactions';
  }

  buildURL(modelName, id, snapshot) {
    let url = super.buildURL(...arguments);

    if (snapshot?.adapterOptions?.useTransactionsEndpoint) {
      url = url.replace(
        `${bookkeepingTransactionsNamespace}/bookkeeping/transactions`,
        `${transactionNamespace}/transactions`
      );
    }

    return url;
  }

  @waitFor
  async getInsights() {
    let payload = await adapterAction(this, 'bookkeeping-transaction', {
      method: 'GET',
      path: 'insights',
    });

    return {
      toVerify: payload.insights?.find(r => r.label === 'to_verify')?.data?.value,
      verified: payload.insights?.find(r => r.label === 'verified')?.data?.value,
    };
  }
}
