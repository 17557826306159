import ApplicationAbility from './application';

export default class ReceiptReminderAbility extends ApplicationAbility {
  get canView() {
    let hasUpdatePermissions = this.canUpdateSettings;

    return hasUpdatePermissions && Boolean(this.pricePlanFeatures.receiptReminder);
  }

  get canUpdateSettings() {
    return this.permissions.receipt_reminders_settings.update;
  }

  get canNavigate() {
    return this.canView && this.canUpdateSettings;
  }
}
