import { service } from '@ember/service';

import ApiAdapter from 'qonto/adapters/base/api';
import { oauthNamespace } from 'qonto/constants/hosts';

export default class ConsentsBundleAdapter extends ApiAdapter {
  @service organizationManager;

  namespace = oauthNamespace;

  pathForType() {
    return 'oauth/consents_bundles';
  }

  buildQuery() {
    let query = super.buildQuery();
    query.organization_id = this.organizationManager.organization.id;
    query.includes = ['memberships', 'clients'];

    return query;
  }
}
